import React, { useEffect, useState, FC, useContext, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import ReactMarkdown from 'react-markdown';
import { LayoutContext } from '../../../../frontastic/provider/layout';
import styles from './amplience-alert-banner.module.scss';

export type AmplienceItemsDesktopPosition = 'left' | 'right' | 'center';

export interface AlertMessage {
  firstMessageLine: { data: any; type: string }[];
  firstMessageLineFontSize: 'h1' | 'h2' | 'h3' | 'h4';
  secondMessageLine?: { data: any; type: string }[];
  secondMessageLineFontSize: 'h1' | 'h2' | 'h3' | 'h4';
  mobileMessageIsDifferent: boolean;
  firstMessageLineMobile?: { data: any; type: string }[];
  secondMessageLineMobile?: { data: any; type: string }[];
}
export interface AmplienceAlertBannerProps {
  content: {
    bannerBackgroundColor: string;
    featuredItemsDesktopPosition: AmplienceItemsDesktopPosition;
    textColor: string;
    alertMessages: AlertMessage[];
  };
}

const AmplienceAlertBanner: FC<AmplienceAlertBannerProps> = ({ content }) => {
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(content.alertMessages[0]);
  const alertMessageIndex = useRef(0);
  const numberOfAlertMessages = useRef(content.alertMessages.length);
  const layout = useContext(LayoutContext);
  const [opacity, setOpacity] = useState(1);
  const [headerDom, setHeaderDom] = useState<HTMLElement>();

  useEffect(() => {
    layout.update.setAlertBanner(true);
    setHeaderDom(document.getElementById('alertBannerPortalTarget'));

    return () => {
      layout.update.setAlertBanner(false);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity(0);
      setTimeout(() => {
        alertMessageIndex.current = (alertMessageIndex.current + 1) % numberOfAlertMessages.current;
        setOpacity(1);
      }, 500);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setAlertMessage(content.alertMessages[alertMessageIndex.current]);
  }, [alertMessageIndex.current]);

  useEffect(() => {
    setAlertMessage(content.alertMessages[0]);
    alertMessageIndex.current = 0;
    numberOfAlertMessages.current = content.alertMessages.length;
    layout.update.setNarrowAlertBanner(
      content.alertMessages.find((message) => message.secondMessageLine?.[0]?.data) !== undefined,
    );
  }, [content]);

  const Heading = ({ type, text }) => {
    const HeadingComponent = React.createElement(type, null, <ReactMarkdown>{text}</ReactMarkdown>);

    return (
      <span
        style={{ textAlign: content.featuredItemsDesktopPosition, color: content.textColor }}
        className={classNames(styles.alertBannerMessage)}
        data-testid="alert-banner-message"
        id="alert-banner-message"
      >
        {HeadingComponent}
      </span>
    );
  };

  return headerDom ? (
    createPortal(
      <div
        id="alert-banner"
        className={classNames(styles.pinkAlertBanner, {
          [styles.pinkAlertBanner__twoLine]: content.alertMessages.find(
            (msg) => msg.secondMessageLine && msg.secondMessageLine[0].data,
          ),
          [styles.pinkAlertBanner__leftAlign]: content.featuredItemsDesktopPosition.toLowerCase() === 'left',
        })}
        style={{ backgroundColor: content.bannerBackgroundColor }}
        data-testid="alert-banner"
      >
        <div style={{ opacity }} className={styles.fade}>
          <Heading
            type={alertMessage.firstMessageLineFontSize}
            text={
              layout.isMobile && alertMessage.firstMessageLineMobile && alertMessage.mobileMessageIsDifferent
                ? alertMessage.firstMessageLineMobile[0].data
                : alertMessage.firstMessageLine[0].data
            }
          />
          {alertMessage.secondMessageLine && alertMessage.secondMessageLine[0]?.data && (
            <Heading
              type={alertMessage.secondMessageLineFontSize}
              text={
                layout.isMobile && alertMessage.secondMessageLineMobile && alertMessage.mobileMessageIsDifferent
                  ? alertMessage.secondMessageLineMobile[0].data
                  : alertMessage.secondMessageLine[0].data
              }
            />
          )}
        </div>
      </div>,
      headerDom,
    )
  ) : (
    <></>
  );
};

export default AmplienceAlertBanner;
