import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import AmplienceAlertBanner from 'components/frontastic-ui/amplience/amplience-alert-banner';
import AmplienceFooter from 'components/frontastic-ui/amplience/amplience-footer';
import AmplienceHeader from 'components/frontastic-ui/amplience/amplience-header';
import { getContentTypeFromSchema } from '../../../../helpers/utils/amplienceUtils';
import styles from './amplience-content.module.scss';

const Breadcrumb = dynamic(() => import('components/commercetools-ui/breadcrumb'), {
  loading: () => <div className={styles.bradcrumbPlaceholder}></div>,
});
const CategoryCarousel = dynamic(() => import('components/commercetools-ui/category-carousel'), {
  loading: () => <div className={styles.categoryCarouselPlaceholder}></div>,
});
const NotFound404 = dynamic(() => import('components/commercetools-ui/notFound404'), {
  loading: () => <div className={styles.notFoundPlacehodler}></div>,
});
const FindUsBannerComponent = dynamic(() => import('components/commercetools-ui/stores/findUsBannerComponent'), {
  loading: () => <div className={styles.findUsBannerPlaceholder}></div>,
});
const FindUsMapComponent = dynamic(() => import('components/commercetools-ui/stores/findUsMapComponent'), {
  loading: () => <div className={styles.findUsMapPlaceholder}></div>,
});
const AmplienceCtaListWrapper = dynamic(() => import('components/frontastic-ui/amplience/ampliance-cta-list-wrapper'), {
  loading: () => <div className={styles.ctaListWrapperPlaceholder}></div>,
});
const AmplienceHeroBanner = dynamic(() => import('components/frontastic-ui/amplience/ampliance-hero-banner'), {
  loading: () => <div className={styles.heroBannerPlaceholder}></div>,
});
const AmplienceAccordion = dynamic(() => import('components/frontastic-ui/amplience/amplience-accordion'), {
  loading: () => <div className={styles.acordionPlaceholder}></div>,
});
const AmplienceAsymmetricalContent = dynamic(
  () => import('components/frontastic-ui/amplience/amplience-asymmetrical-content'),
  {
    loading: () => <div className={styles.asymetricalContentPlaceholder}></div>,
  },
);
const AmplienceContentCarousel = dynamic(
  () => import('components/frontastic-ui/amplience/amplience-content-carousel'),
  {
    loading: () => <div className={styles.contentCarouselPlaceholder}></div>,
  },
);
const AmplienceDynamicContent = dynamic(() => import('components/frontastic-ui/amplience/amplience-dynamic-content'), {
  loading: () => <div className={styles.dynamicContentPlaceholder}></div>,
});
const AmplienceFitAndSizeCollection = dynamic(
  () => import('components/frontastic-ui/amplience/amplience-fit-and-size-guide'),
  {
    loading: () => <div className={styles.fitAndSizeGuideCollectionPlaceholder}></div>,
  },
);
const SizeGuideSizeChart = dynamic(
  () => import('components/frontastic-ui/amplience/amplience-fit-and-size-guide/sizeGuide-size-chart'),
  {
    loading: () => <div className={styles.sizeGuideChartPlaceholder}></div>,
  },
);

const AmpliencePinkBulletinBanner = dynamic(
  () => import('components/frontastic-ui/amplience/amplience-pink-bulletin-banner'),
  {
    loading: () => <div className={styles.pinkBulletinBannerPlaceholder}></div>,
  },
);
const AmpliencePinkBulletinFiftyFifty = dynamic(
  () => import('components/frontastic-ui/amplience/amplience-pink-bulletin-fifty-fifty'),
  {
    loading: () => <div className={styles.fiftyfiftyPlaceholder}></div>,
  },
);
const AmpliencePinkBulletinImageComponent = dynamic(
  () => import('components/frontastic-ui/amplience/amplience-pink-bulletin-image-component'),
  {
    loading: () => <div className={styles.pinkBulletinImagePlaceholder}></div>,
  },
);
const AmpliencePinkBulletinTags = dynamic(
  () => import('components/frontastic-ui/amplience/amplience-pink-bulletin-tags'),
  {
    loading: () => <div className={styles.pinkBulletinTagsPlaceholder}></div>,
  },
);
const AmpliencePlpBanner = dynamic(() => import('components/frontastic-ui/amplience/amplience-plp-banner'), {
  loading: () => <div className={styles.plpBannerPlaceholder}></div>,
});
const AmplienceProductCarousel = dynamic(
  () => import('components/frontastic-ui/amplience/amplience-product-carousel'),
  {
    loading: () => <div className={styles.productCarouselPlaceholder}></div>,
  },
);
const AmplienceSpacer = dynamic(() => import('components/frontastic-ui/amplience/amplience-spacer'), {
  loading: () => <div className={styles.spacerPlacehodler}></div>,
});
const AmplienceTableOfContents = dynamic(
  () => import('components/frontastic-ui/amplience/amplience-table-of-contents'),
  {
    loading: () => <div className={styles.tableOfContentsPlaceholder}></div>,
  },
);
const AmplienceTextComponent = dynamic(() => import('components/frontastic-ui/amplience/amplience-text-component'), {
  loading: () => <div className={styles.textComponentPlaceholder}></div>,
});
const AmplienceSimpleContent = dynamic(() => import('components/frontastic-ui/amplience/simple-content'), {
  loading: () => <div className={styles.simpleContentPlaceholder}></div>,
});
const AmplienceStoreServices = dynamic(
  () => import('../../../../components/frontastic-ui/amplience/amplience-store-services'),
  {
    loading: () => <div className={styles.storeServicesPlaceholder}></div>,
  },
);
const AmplienceSingleImageComponent = dynamic(
  () => import('../../../../components/frontastic-ui/amplience/amplience-single-image-component'),
  {
    loading: () => <div className={styles.imageComponentPlaceholder}></div>,
  },
);
const AmplienceDynamicPage = dynamic(() => import('../dynamic-page'));

const AmplienceDynamicContentTastic = ({ data }) => {
  const [content, setContent] = useState(data?.content ? data.content : data?.data?.dataSource?.content);
  //Content types correspond to ampilence schema names
  const [contentType, setContentType] = useState(
    getContentTypeFromSchema(
      data?.content ? data.content?._meta.schema : data?.data?.dataSource?.content?._meta?.schema,
    ),
  );

  useEffect(() => {
    setContent(data?.content ? data.content : data?.data?.dataSource?.content);
    setContentType(
      getContentTypeFromSchema(
        data?.content ? data.content?._meta.schema : data?.data?.dataSource?.content?._meta?.schema,
      ),
    );
  }, [data]);

  if (contentType === 'page') {
    return <AmplienceDynamicPage content={content} />;
  } else if (contentType === 'pink-bulletin') {
    return <AmplienceDynamicPage content={content} />;
  } else if (contentType === 'header') {
    return <AmplienceHeader content={content} />;
  } else if (contentType === 'footer') {
    return <AmplienceFooter content={content} />;
  } else if (contentType === 'alertBanner') {
    return <AmplienceAlertBanner content={content} />;
  } else if (contentType === 'simple-content') {
    return <AmplienceSimpleContent content={content} bleedEffect={true} />;
  } else if (contentType === 'plp-banner') {
    return <AmpliencePlpBanner content={content} />;
  } else if (contentType === 'hero-banner') {
    return <AmplienceHeroBanner content={content} />;
  } else if (contentType === 'asymmetrical-content') {
    return <AmplienceAsymmetricalContent content={content} />;
  } else if (contentType === 'product-selector') {
    return <AmplienceProductCarousel content={content} />;
  } else if (contentType === 'category-carousel') {
    return <CategoryCarousel content={content} variation="page-count" />;
  } else if (contentType === 'table-of-content') {
    return <AmplienceTableOfContents content={content} />;
  } else if (contentType === 'accordion') {
    return <AmplienceAccordion content={content} />;
  } else if (contentType === 'content-carousel') {
    return <AmplienceContentCarousel content={content} />;
  } else if (contentType === 'fit&size-collection') {
    return <AmplienceFitAndSizeCollection content={content} />;
  } else if (contentType === 'fit&size-chart') {
    return <SizeGuideSizeChart content={content} />;
  } else if (contentType === 'find-us-banner') {
    return <FindUsBannerComponent content={content} />;
  } else if (contentType === 'find-us-map') {
    return <FindUsMapComponent content={content} />;
  } else if (contentType === 'store-services') {
    return <AmplienceStoreServices content={content} />;
  } else if (contentType === 'text') {
    return <AmplienceTextComponent content={content} />;
  } else if (contentType === 'spacer') {
    return <AmplienceSpacer content={content} />;
  } else if (contentType === 'cta-list-wrapper') {
    return <AmplienceCtaListWrapper content={content} />;
  } else if (contentType === 'single-image-component') {
    return <AmplienceSingleImageComponent content={content} />;
  } else if (contentType === 'pink-bulletin-banner') {
    return <AmpliencePinkBulletinBanner content={content} />;
  } else if (contentType === 'pink-bulletin-image-component') {
    return <AmpliencePinkBulletinImageComponent content={content} />;
  } else if (contentType === 'pink-bulletin-fifty-fifty') {
    return <AmpliencePinkBulletinFiftyFifty content={content} />;
  } else if (contentType === 'pink-bulletin-tags') {
    return <AmpliencePinkBulletinTags content={content} />;
  } else if (contentType === 'breadcrumbs') {
    return <Breadcrumb content={content} />;
  } else if (contentType === '404-not-found-banner') {
    return <NotFound404 content={content} />;
  } else {
    return <AmplienceDynamicContent content={content} />;
  }
};

export default AmplienceDynamicContentTastic;
